<template>
  <nav class="navs">
    <template v-if="isPlanButtonAllowed">
      <NuxtLink
        class="nav"
        :class="{ 'nav--active': route.path === item.link, 'nav--none': hideIcon(item.text) }"
        :to="item.link"
        v-for="(item, index) in navigation"
        :key="index"
      >
        <v-tooltip
          class="tooltip"
          :text="item.text"
          location="top"
        >
          <template #activator="{ props }">
            <v-icon
              class="icon"
              v-bind="props"
              :class="{ 'icon--learge': item.icon === 'mdi-account-cog' }"
            >
              {{ item.icon }}
            </v-icon>

            <span class="text">
              {{ item.text }}
            </span>
          </template>
        </v-tooltip>
      </NuxtLink>
    </template>

    <v-tooltip
      class="tooltip"
      text="色変更"
      location="top"
    >
      <template #activator="{ props }">
        <v-icon
          class="icon icon--hidden"
          v-bind="props"
          @click="switchTheme"
        >
          mdi-theme-light-dark
        </v-icon>
      </template>
    </v-tooltip>

    <v-tooltip
      class="tooltip"
      text="ログアウト"
      location="top"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          class="icon icon--hidden"
          @click="logout"
        >
          mdi-logout
        </v-icon>
      </template>
    </v-tooltip>
  </nav>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify'
import { isPlanButtonAllowedOnRoute } from '@/utils/plan-button-route-check'
import { MY_RENDERER_STATUS } from '~/constants/account-status'

const route = useRoute()
const theme = useTheme()
const accountStore = useAccountStore()
const user = computed(() => accountStore.user)
const currentTheme = ref(theme.global.name)
const isPlanButtonAllowed: boolean = isPlanButtonAllowedOnRoute(['/consent', '/verify', '/profile', '/plan', '/subscriptions/success', '/maintenance'], route.path)
const navigation = [
  { link: '/generate', icon: 'mdi-creation', text: '作業画面' },
  { link: 'https://docs.google.com/forms/d/e/1FAIpQLSelA3U5kRiEP1UvYbIzJ5XJ4GO4GGbhfvU7t-3RFXwHJXF3Pw/viewform?usp=pp_url&entry.1782166726=%E7%A2%BA%E8%AA%8D%E3%81%97%E3%81%BE%E3%81%97%E3%81%9F%E3%80%82', icon: 'mdi-pen', text: '部分修正(有償)' },
  { link: '/artifacts', icon: 'mdi-image-multiple', text: '画像一覧' },
  { link: '/usages', icon: 'mdi-chart-bar', text: '使用量' },
  { link: '/settings', icon: 'mdi-account-cog', text: '設定' },
]

const hideIcon = (text: string) => {
  return text === '部分修正' && user.value?.myRendererStatus !== MY_RENDERER_STATUS.subscribed.id
}

const switchTheme = () => {
  currentTheme.value = currentTheme.value === 'light' ? 'dark' : 'light'
}

const logout = async () => {
  accountStore.reset()
  window.location.href = '/logout'
}
</script>

<style lang="scss" scoped>
.navs {
  padding: 0.5rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary));

  @include media(sm) {
    order: 2;
    padding: 0.75rem 0.5rem;
    justify-content: space-around;
    background-color: rgb(var(--v-theme-background));
  }

  > .nav {
    font-size: 1rem;
    gap: 0.25rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover {
      color: rgb(var(--v-theme-primary-light));
    }

    &--active {
      color: rgb(var(--v-theme-primary-light));
    }

    &--none {
      display: none;
    }

    > .text {
      display: none;

      @include media(sm) {
        display: block;
        font-size: 0.685rem;
      }
    }
  }
}

.icon {
  font-size: 1.25rem;

  &--learge {
    font-size: 1.5rem;
  }

  &--hidden {
    @include media(sm) {
      display: none;
    }
  }
}

.tooltip {
  @include media(sm) {
    display: none;
  }
}
</style>
